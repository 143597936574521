import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';
export const getSurvey = createAsyncThunk('surveySlice/getSurvey', async () => {
    try {
        let response = await axios.get('/survey', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSurveyById = createAsyncThunk('surveySlice/getSurveyById', async (id) => {
    try {
        let response = await axios.get('/survey/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getSurveyPredictionById = createAsyncThunk('surveySlice/getSurveyPredictionById', async (data) => {
    try {
        let response = await axios.post('/prediction/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const addSurveyPredictionById = createAsyncThunk('surveySlice/addSurveyPredictionById', async (data) => {
    try {
        let response = await axios.post('/prediction', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createSurvey = createAsyncThunk('surveySlice/createSurvey', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/survey', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Survey  added successfully.');
        dispatch(getSurvey());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateSurvey = createAsyncThunk('surveySlice/updateSurvey', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/survey/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Survey updated successfully.');
        dispatch(getSurvey());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const deleteSurvey = createAsyncThunk('surveySlice/deleteSurvey', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/survey/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Survey deleted successfully.');
        await dispatch(getSurvey());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('surveySlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/survey/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Survey Status updated successfully !');
        dispatch(getSurvey());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getSurveyOptions = createAsyncThunk('surveySlice/getSurveyOptions', async (id) => {
    try {
        const query = queryString.stringify(id);
        let response = await axios.get('/survey/options' + `${id ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const surveySlice = createSlice({
    name: 'surveySlice',
    initialState: {
        data: [],
        options: [],
        selectedData: {},
        prediction: {}
    },

    reducers: {
        removeSelectedSurvey: (state, action) => {
            state.selectedData = {};
        },
        removePrediction: (state, action) => {
            state.prediction = {};
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getSurvey.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getSurveyOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
        builder.addCase(getSurveyById.fulfilled, (state, action) => {
            state.selectedData = action?.payload;
            return;
        });
        builder.addCase(getSurveyPredictionById.fulfilled, (state, action) => {
            state.prediction = action?.payload;
            return;
        });
    }
});

export const { removeSelectedSurvey, removePrediction } = surveySlice.actions;

export default surveySlice.reducer;
