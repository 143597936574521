import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';

// Loader Components
import queryString from 'query-string';
export const getLeader = createAsyncThunk('leaderSlice/getLeader', async () => {
    try {
        let response = await axios.get('/app-user', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });

        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createLeader = createAsyncThunk('leaderSlice/createLeader', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/app-user', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'User created successfully.');
        dispatch(getLeader());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateLeader = createAsyncThunk('leaderSlice/updateLeader', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/app-user/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'User updated successfully.');
        dispatch(getLeader());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const getLeaderById = createAsyncThunk('leaderSlice/getLeaderById', async (id) => {
    try {
        let response = await axios.get('/app-user/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const deleteLeader = createAsyncThunk('leaderSlice/deleteLeader', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/app-user/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Leader deleted successfully');
        dispatch(getLeader());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateStatus = createAsyncThunk('leaderSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/app-user/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Leader Status updated successfully');
        dispatch(getLeader());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.err(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getAppUserOptions = createAsyncThunk('leaderSlice/getAppUserById', async (id) => {
    try {
        const query = queryString.stringify(id);
        let response = await axios.get('/app-user/options' + `${id ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const leaderSlice = createSlice({
    name: 'leaderSlice',
    initialState: {
        data: [],
        options: [],
        selectedUser: {}
    },
    reducers: {
        handleSelectedUser: (state, action) => {
            state.selectedUser = action.payload;
        },
        removeSelectedUser: (state, action) => {
            state.selectedUser = {};
        }
    },
    extraReducers: (builder) => {
        builder.addCase(getLeader.fulfilled, (state, action) => {
            state.data = action.payload;
            return;
        });
        builder.addCase(getLeaderById.fulfilled, (state, action) => {
            state.selectedUser = action.payload;
            return;
        });
        builder.addCase(getAppUserOptions.fulfilled, (state, action) => {
            state.options = action.payload;
            return;
        });
    }
});

export const { handleSelectedUser, removeSelectedUser } = leaderSlice.actions;

export default leaderSlice.reducer;
