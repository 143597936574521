import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getElection = createAsyncThunk('electionSlice/getElection', async () => {
    try {
        let response = await axios.get('/election', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createElection = createAsyncThunk('electionSlice/createElection', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/election', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'City added successfully.');
        dispatch(getElection());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateElection = createAsyncThunk('electionSlice/updateElection', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/election/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Election updated successfully.');
        dispatch(getElection());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});
export const getElectionYearOptions = createAsyncThunk('electionSlice/getElectionYearOptions', async () => {
    try {
        let response = await axios.get('/year/options', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const deleteElection = createAsyncThunk('electionSlice/deleteElection', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/election/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Election deleted successfully.');
        await dispatch(getElection());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('electionSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/election/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Election Status updated successfully !');
        dispatch(getElection());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getElectionOptions = createAsyncThunk('electionSlice/getElectionOptions', async () => {
    try {
        let response = await axios.get('/election/options', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const electionSlice = createSlice({
    name: 'electionSlice',
    initialState: {
        data: [],
        yearOptions: [],
        electionOptions: []
    },

    extraReducers: (builder) => {
        builder.addCase(getElection.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getElectionYearOptions.fulfilled, (state, action) => {
            state.yearOptions = action?.payload;
            return;
        });
        builder.addCase(getElectionOptions.fulfilled, (state, action) => {
            state.electionOptions = action?.payload;
            return;
        });
    }
});

export default electionSlice.reducer;
