import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';
import showLoader from '@components/loader';

export const getVoter = createAsyncThunk('voterSlice/getVoter', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/get/voter/with-filters', data.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        dispatch(removeSelectedVoter());
        return Promise.resolve(response.data.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVoterForFamily = createAsyncThunk('voterSlice/getVoterForFamily', async (data, { dispatch }) => {
    try {
        let response = await axios.post('/voter/family-member/with-filters', data.data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVoterFamily = createAsyncThunk('voterSlice/getVoterFamily', async (id, { dispatch }) => {
    try {
        let response = await axios.get('/voter/member/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVoterById = createAsyncThunk('voterSlice/getVoterById', async (id) => {
    try {
        let response = await axios.get('/voter/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const voterBulkImport = createAsyncThunk('voterSlice/voterBulkImport', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/voter-bulk-create', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Bulk Import successfully !');
        await dispatch(getVoter());
        return Promise.resolve(response);
    } catch (err) {
        // toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        // return Promise.reject(err);
        return rejectWithValue(err?.response?.data);
    }
});

// bulk delete
export const deleteBulkVoters = createAsyncThunk('voterSlice/deleteBulkVoters', async (deleteIds, { dispatch }) => {
    try {
        let response = await axios.put('/voter-bulk-delete', deleteIds, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Voters deleted successfully.');
        // await dispatch(getVoter());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const createVoter = createAsyncThunk('voterSlice/createVoter', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/voter', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Voter created successfully.');
        dispatch(getVoter());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});
export const deleteVoter = createAsyncThunk('voterSlice/deleteLeader', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/voter/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'voter deleted successfully');
        dispatch(getVoter());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const updateVoter = createAsyncThunk('voterSlice/updateVoter', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/voter/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Voter updated successfully.');
        dispatch(getVoter());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateVoterSingleField = createAsyncThunk('voterSlice/updateVoterSingleField', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/voter/single-update/' + data?.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Voter updated successfully.');
        dispatch(getVoter());
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateFamilyHead = createAsyncThunk('voterSlice/updateFamilyHead', async (id, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/add-voter-family-head/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Family Head change successfully.');
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const getVoterInformationTimeLine = createAsyncThunk('voterSlice/getVoterInformationTimeLine', async (data, { dispatch }) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/voter/voter-information?' + query, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getVoterContributionTimeLine = createAsyncThunk('voterSlice/getVoterContributionTimeLine', async (data, { dispatch }) => {
    try {
        const query = queryString.stringify(data);
        let response = await axios.get('/voter/schema-contribution?' + query, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response?.data?.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        console.log('err', err);
        return Promise.reject(err);
    }
});

export const getVoterOptions = createAsyncThunk('voterSlice/getVoterOptions', async () => {
    try {
        let response = await axios.post('/get/voter/options', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const addVoterFamily = createAsyncThunk('voterSlice/addVoterFamily', async (data) => {
    try {
        showLoader(true);
        let response = await axios.post('/voter-family-member', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Member Added successfully.');
        showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const removeVoterFamily = createAsyncThunk('voterSlice/removeVoterFamily', async (id) => {
    try {
        showLoader(true);
        let response = await axios.put('/remove-voter-family-member/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Member Remove successfully.');
        showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const addSelfFamily = createAsyncThunk('voterSlice/addSelfFamily', async (data) => {
    try {
        showLoader(true);
        let response = await axios.post('/add-self-family', data, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Member Added successfully.');
        showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const removeSelfFamily = createAsyncThunk('voterSlice/removeSelfFamily', async (id) => {
    try {
        showLoader(true);
        let response = await axios.put('/remove-self-family/' + id, {
            headers: {
                'Content-Type': 'application/json'
            }
        });
        toast.success(response?.data?.message || 'Member Remove successfully.');
        showLoader(false);
        return Promise.resolve(response.data.data);
    } catch (err) {
        showLoader(false);
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVoterPredictions = createAsyncThunk('voterSlice/getVoterPredictions', async (data) => {
    try {
        let response = await axios.post('/voter/with-prediction/with-filters', data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getCasteOptions = createAsyncThunk('voterSlice/getCasteOptions', async () => {
    try {
        let response = await axios.get('/cast/options', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const voterSlice = createSlice({
    name: 'voterSlice',
    initialState: {
        data: {},
        selectedVoter: {},
        options: [],
        voterFamily: {},
        casteOptions: []
    },
    reducers: {
        removeSelectedVoter: (state, action) => {
            state.selectedVoter = {};
        }
    },

    extraReducers: (builder) => {
        builder.addCase(getVoter.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getVoterFamily.fulfilled, (state, action) => {
            state.voterFamily = action?.payload;
            return;
        });
        builder.addCase(getVoterById.fulfilled, (state, action) => {
            state.selectedVoter = action?.payload;
            return;
        });
        builder.addCase(getVoterOptions.fulfilled, (state, action) => {
            state.options = action?.payload;
            return;
        });
        builder.addCase(getCasteOptions.fulfilled, (state, action) => {
            state.casteOptions = action?.payload;
            return;
        });
    }
});

export const { removeSelectedVoter } = voterSlice.actions;

export default voterSlice.reducer;
