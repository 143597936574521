import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../../service/axios';
import { toast } from 'react-toastify';

export const getCast = createAsyncThunk('castSlice/getCast', async () => {
    try {
        let response = await axios.get('/cast', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const createCast = createAsyncThunk('castSlice/createCast', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.post('/cast', data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Caste added successfully.');
        dispatch(getCast());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const updateCast = createAsyncThunk('castSlice/updateCast', async (data, { dispatch, rejectWithValue }) => {
    try {
        let response = await axios.put('/cast/' + data.id, data?.data, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Caste updated successfully.');
        dispatch(getCast());
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return rejectWithValue(err);
    }
});

export const deleteCast = createAsyncThunk('castSlice/deleteCast', async (deleteId, { dispatch }) => {
    try {
        let response = await axios.delete('/cast/' + deleteId, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Caste deleted successfully.');
        await dispatch(getCast());
        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const updateStatus = createAsyncThunk('castSlice/updateStatus', async (id, { dispatch }) => {
    try {
        let response = await axios.put('/cast/status/' + id, undefined, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: true
        });
        toast.success(response?.data?.message || 'Caste Status updated successfully !');
        dispatch(getCast());

        return Promise.resolve(response);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

// export const getLokSabhaOptions = createAsyncThunk('castSlice/getLokSabhaOptions', async () => {
//     try {
//         let response = await axios.get('/lok-sabha/options', {
//             headers: {
//                 'Content-Type': 'application/json'
//             }
//         });
//         return Promise.resolve(response.data.data);
//     } catch (err) {
//         toast.error(err?.response?.data?.message || 'Something went wrong!');
//         return Promise.reject(err);
//     }
// });

export const castSlice = createSlice({
    name: 'castSlice',
    initialState: {
        data: []
        // options: []
    },

    extraReducers: (builder) => {
        builder.addCase(getCast.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        // builder.addCase(getLokSabhaOptions.fulfilled, (state, action) => {
        //     state.options = action?.payload;
        //     return;
        // });
    }
});

export default castSlice.reducer;
