import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '../../../service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';

export const getCasteReport = createAsyncThunk('reportSlice/getCasteReport', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/report/caste' + `${data ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getElectionResultReport = createAsyncThunk('reportSlice/getElectionResultReport', async (data) => {
    try {
        const query = queryString.stringify(data);

        let response = await axios.get('/report/election-result' + `${data ? `?${query}` : ''}`, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const reportSlice = createSlice({
    name: 'reportSlice',
    initialState: {
        casteReport: [],
        electionResultReport: []
    },

    extraReducers: (builder) => {
        builder.addCase(getCasteReport.fulfilled, (state, action) => {
            state.casteReport = action?.payload;
            return;
        });
        builder.addCase(getElectionResultReport.fulfilled, (state, action) => {
            state.electionResultReport = action?.payload;
            return;
        });
    }
});

export default reportSlice.reducer;
