import { createAsyncThunk, createSlice } from '@reduxjs/toolkit';
import axios from '@src/service/axios';
import { toast } from 'react-toastify';
import queryString from 'query-string';
// Loader Components
import showLoader from '@components/loader';

export const getPredictionoptions = createAsyncThunk('dashboardSlice/getPredictionoptions', async (id) => {
    try {
        let response = await axios.get('/dashboard/election-prediction/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getPreElection = createAsyncThunk('dashboardSlice/getPreElection', async (id) => {
    try {
        let response = await axios.get('/dashboard/election-pre-election/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getOnElection = createAsyncThunk('dashboardSlice/getOnElection', async (id) => {
    try {
        let response = await axios.get('/dashboard/election-on-election/' + id, {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getConstituencyCount = createAsyncThunk('dashboardSlice/getConstituencyCount', async () => {
    try {
        let response = await axios.get('/dashboard/constituency-count', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVidhanSabhaCountDetails = createAsyncThunk('dashboardSlice/getVidhanSabhaCountDetails', async () => {
    try {
        let response = await axios.get('/dashboard/vidhan-sabha-count', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getTalukaCountDetails = createAsyncThunk('dashboardSlice/getTalukaCountDetails', async () => {
    try {
        let response = await axios.get('/dashboard/taluka-count', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getZillaParishadCountDetails = createAsyncThunk('dashboardSlice/getZillaParishadCountDetails', async () => {
    try {
        let response = await axios.get('/dashboard/zilla-parishad-count', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getNagarParishadCountDetails = createAsyncThunk('dashboardSlice/getNagarParishadCountDetails', async () => {
    try {
        let response = await axios.get('/dashboard/nagar-parishad-count', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getNagarPanchayatCountDetails = createAsyncThunk('dashboardSlice/getNagarPanchayatCountDetails', async () => {
    try {
        let response = await axios.get('/dashboard/nagar-panchayat-count', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getPanchayatSumitteeCountDetails = createAsyncThunk('dashboardSlice/getPanchayatSumitteeCountDetails', async () => {
    try {
        let response = await axios.get('/dashboard/panchayat-sumittee-count', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getGramPanchayatCountDetails = createAsyncThunk('dashboardSlice/getGramPanchayatCountDetails', async () => {
    try {
        let response = await axios.get('/dashboard/gram-panchayat-count', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const getVillageCountDetails = createAsyncThunk('dashboardSlice/getVillageCountDetails', async () => {
    try {
        let response = await axios.get('/dashboard/village-count', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});
export const getBoothCountDetails = createAsyncThunk('dashboardSlice/getBoothCountDetails', async () => {
    try {
        let response = await axios.get('/dashboard/booth-count', {
            headers: {
                'Content-Type': 'application/json'
            },
            loader: false
        });
        return Promise.resolve(response.data.data);
    } catch (err) {
        toast.error(err?.response?.data?.message || 'Something went wrong!');
        return Promise.reject(err);
    }
});

export const dashboardSlice = createSlice({
    name: 'dashboardSlice',
    initialState: {
        data: [],
        preElection: [],
        onElection: [],
        constituencyCount: {}
    },

    extraReducers: (builder) => {
        builder.addCase(getPredictionoptions.fulfilled, (state, action) => {
            state.data = action?.payload;
            return;
        });
        builder.addCase(getPreElection.fulfilled, (state, action) => {
            state.preElection = action?.payload;
            return;
        });
        builder.addCase(getOnElection.fulfilled, (state, action) => {
            state.onElection = action?.payload;
            return;
        });
        builder.addCase(getConstituencyCount.fulfilled, (state, action) => {
            state.constituencyCount = action?.payload;
            return;
        });
    }
});

export default dashboardSlice.reducer;
